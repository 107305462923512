export const redirectToAppStore = () => {
  const url = window.location.href;

  const regex = /[?&]utm_campaign=([^&]+)/;
  const match = url.match(regex);

  console.log("match", match);

  console.log("window.location.pathname", window.location.pathname);

  if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    window.location.href =
      "https://apps.apple.com/kr/app/id6460819474?utm_source=sagongsa_web&utm_medium=organic&utm_campaign=view_app&utm_term=view_app";
  } else if (window.navigator.userAgent.match(/Android/i)) {
    if (match != null && match[1] === "web_traffic_10.31_discount_apple") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postlab.sagongsa&referrer=utm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3Dweb_traffic_10.31_discount_apple";
    } else if (
      match != null &&
      match[1] === "web_traffic_10.31_discount_other"
    ) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postlab.sagongsa&referrer=utm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3Dweb_traffic_10.31_discount_other";
    } else if (
      match != null &&
      match[1] === "web_traffic_10.31_average_apple"
    ) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postlab.sagongsa&referrer=utm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3Dweb_traffic_10.31_average_apple";
    } else if (
      match != null &&
      match[1] === "web_traffic_10.31_average_other"
    ) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postlab.sagongsa&referrer=utm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3Dweb_traffic_10.31_average_other";
    } else if (match != null && match[1] === "web_traffic_10.31_hogu_apple") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postlab.sagongsa&referrer=utm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3Dweb_traffic_10.31_hogu_apple";
    } else if (match != null && match[1] === "web_traffic_10.31_hogu_other") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postlab.sagongsa&referrer=utm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3Dweb_traffic_10.31_hogu_other";
    } else if (window.location.pathname === "/apple") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postlab.sagongsa&referrer=utm_source%3Dgoogle%20%26utm_medium%3Dcpc%26utm_campaign%3Dsa_hogu_apple_10.24";
    } else if (window.location.pathname === "/other") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postlab.sagongsa&referrer=utm_source%3Dgoogle%20%26utm_medium%3Dcpc%26utm_campaign%3Dsa_hogu_other_10.24";
    } else if (match != null && match[1] === "coupon") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postlab.sagongsa&referrer=utm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3Dweb_traffic_10.31_coupon";
    } else if (match != null && match[1] === "discount") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postlab.sagongsa&referrer=utm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3Dweb_traffic_10.31_discount";
    } else if (match != null && match[1] === "averageprice") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postlab.sagongsa&referrer=utm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3Dweb_traffic_10.31_averageprice";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postlab.sagongsa&utm_source=sagongsa_web&utm_medium=organic&utm_campaign=view_app&utm_term=view_app";
    }
  }
};
