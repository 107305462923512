import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import useWidth from "../hook/useWidth";
import SideMenu from "./old/SideMenu";
import { useRef } from "react";
import * as style from "./WebHeader.style";

const WebHeader = () => {
  const [browserWidth] = useWidth(window.innerWidth);

  const [headerOpacity, setHeaderOpacity] = useState(0);

  const [mobileMenu, setMobileMenu] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollHeight =
        browserWidth > 791 ? window.outerHeight - 300 : window.outerHeight - 50;
      if (scrollPosition <= scrollHeight) {
        setHeaderOpacity(0);
      } else {
        const opacity =
          scrollPosition /
          (browserWidth > 791 ? scrollHeight + 600 : scrollHeight + 100);
        setHeaderOpacity(opacity);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [browserWidth]);

  useEffect(() => {
    const handler = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setMobileMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <style.MainWrapper headerOpacity={headerOpacity}>
        {window.scrollY <=
        (browserWidth > 791
          ? window.outerHeight - 300
          : window.outerHeight - 50) ? (
          <style.LogoImg
            alt=""
            onClick={() => {
              window.location.reload();
              scrollToTop();
            }}
            className="logo"
            src={`${process.env.PUBLIC_URL}/assets/GNB_logo_white.png`}
          />
        ) : (
          <style.LogoImg
            alt=""
            onClick={() => {
              window.location.reload();
              scrollToTop();
            }}
            className="logo"
            src={`${process.env.PUBLIC_URL}/assets/GNB_logo.png`}
          />
        )}
        <style.RightSection>
          {browserWidth > 791 ? (
            <>
              {" "}
              <h2
                onClick={() =>
                  (window.location.href = "mailto:contact@postlabs.io")
                }
                className="btn Inquire"
                style={{
                  color: window.scrollY <= window.outerHeight - 300 ? "white" : "black",
                }}
              >
                문의하기
              </h2>
              <a
                href="https://www.wanted.co.kr/company/35924"
                target="_blank"
                rel="noopener noreferrer"
                textDecoration="none"
                color="inherit"
                style={{ textDecoration: "none", color: "black" }}
              >
                <h1 className="button">지원하기</h1>
              </a>
            </>
          ) : (
            <img
              alt=""
              onClick={() => setMobileMenu(!mobileMenu)}
              className="hamburger"
              src={
                window.scrollY <=
                (browserWidth > 791
                  ? window.outerHeight - 300
                  : window.outerHeight - 50)
                  ? `${process.env.PUBLIC_URL}/assets/hamburger_white.png`
                  : `${process.env.PUBLIC_URL}/assets/hamburger.png`
              }
            />
          )}
        </style.RightSection>
      </style.MainWrapper>
      <SideMenu
        testRef={ref}
        mobileMenu={mobileMenu}
        setMobileMenu={setMobileMenu}
      />
    </>
  );
};

export default WebHeader;
