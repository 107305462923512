import { css } from "styled-components";

//Mono
export const MonoBlack = css`
  color: black;
`;
export const MonoWhite = css`
  color: white;
`;

//Grayscale
export const Gray900 = css`
  color: #111111;
`;
export const Gray800 = css`
  color: #303030;
`;
export const Gray700 = css`
  color: #555555;
`;
export const Gray600 = css`
  color: #858585;
`;
export const Gray500 = css`
  color: #999999;
`;
export const Gray400 = css`
  color: #c2c2c2;
`;
export const Gray300 = css`
  color: #d6d6d6;
`;
export const Gray200 = css`
  color: #ebebeb;
`;
export const Gray100 = css`
  color: #f5f5f5;
`;
export const Gray050 = css`
  color: #f8f8f8;
`;
export const Gray010 = css`
  color: #fafafa;
`;

//purple
export const Purple900 = css`
  color: #5a00ff;
`;

export const Purple800 = css`
  color: #6b1aff;
`;

export const Purple700 = css`
  color: #7b33ff;
`;

export const Purple600 = css`
  color: #8b4dff;
`;

export const Purple500 = css`
  color: #9c66ff;
`;

export const Purple400 = css`
  color: #ac80ff;
`;

export const Purple300 = css`
  color: #bd99ff;
`;

export const Purple200 = css`
  color: #cdb2ff;
`;

export const Purple100 = css`
  color: #deccff;
`;

export const Purple050 = css`
  color: #eee5ff;
`;

//green
export const Green900 = css`
  color: #15bd66;
`;

export const Green800 = css`
  color: #44ca85;
`;

export const Green700 = css`
  color: #5bd194;
`;

export const Green600 = css`
  color: #73d7a3;
`;

export const Green500 = css`
  color: #8adeb2;
`;

export const Green400 = css`
  color: #a1e5c2;
`;

export const Green300 = css`
  color: #b9ebd1;
`;

export const Green200 = css`
  color: #d0f2e0;
`;

export const Green100 = css`
  color: #e8f8f0;
`;

//red

export const Red900 = css`
  color: #ff334b;
`;

export const Red800 = css`
  color: #ff5c6f;
`;

export const Red700 = css`
  color: #ff7081;
`;

export const Red600 = css`
  color: #ff8593;
`;

export const Red500 = css`
  color: #ff99a5;
`;

export const Red400 = css`
  color: #ffadb7;
`;

export const Red300 = css`
  color: #ffc2c9;
`;

export const Red200 = css`
  color: #ffd6db;
`;

export const Red100 = css`
  color: #ffebed;
`;

//yellow
export const Yellow900 = css`
  color: #ffc53d;
`;

export const Yellow800 = css`
  color: #ffd164;
`;
export const Yellow700 = css`
  color: #ffd677;
`;
export const Yellow600 = css`
  color: #ffdc8b;
`;
export const Yellow500 = css`
  color: #ffe29e;
`;
export const Yellow400 = css`
  color: #ffe8b1;
`;
export const Yellow300 = css`
  color: #ffeec5;
`;
export const Yellow200 = css`
  color: #fff3d8;
`;
export const Yellow100 = css`
  color: #fff9ec;
`;

//blue

export const Blue900 = css`
  color: #1a9cff;
`;

export const Blue800 = css`
  color: #48b0ff;
`;

export const Blue700 = css`
  color: #5fbaff;
`;

export const Blue600 = css`
  color: #76c4ff;
`;

export const Blue500 = css`
  color: #8ccdff;
`;

export const Blue400 = css`
  color: #a3d7ff;
`;

export const Blue300 = css`
  color: #bae1ff;
`;

export const Blue200 = css`
  color: #d1ebff;
`;

export const Blue100 = css`
  color: #e8f5ff;
`;

//deepBlue

export const DeepBlue900 = css`
  color: #4270ed;
`;

export const DeepBlue800 = css`
  color: #688df1;
`;

export const DeepBlue700 = css`
  color: #7b9bf2;
`;

export const DeepBlue600 = css`
  color: #8ea9f4;
`;

export const DeepBlue500 = css`
  color: #a0b7f6;
`;

export const DeepBlue400 = css`
  color: #b3c6f8;
`;

export const DeepBlue300 = css`
  color: #c6d4fa;
`;

export const DeepBlue200 = css`
  color: #d9e2fb;
`;

export const DeepBlue100 = css`
  color: #ecf1fd;
`;
