import React, { useRef } from "react";
import * as style from "./SecondPage.style";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

function MotionSection({ children, isImage, isMobile }) {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden")
    }
  }, [isInView, mainControls]);

  return (
    <section ref={ref}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: isImage ? 100 : 200 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{
          ease: "easeInOut",
          duration: isImage ? 1 : 0.5,
          delay: isImage ? 0.25 : isMobile ? 0.3 : 0.4,
        }}
      >
        {children}
      </motion.div>
    </section>
  );
}

const SecondPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  const mobile = useMediaQuery({
    query: "(max-width:791px)",
  });

  useEffect(() => {
    if (mobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [mobile]);

  return (
    <style.SecondPageWrapper id="SecondPageWrapper">
      <style.TextArea>
        {isMobile ? (
          <div>
            <p>쇼핑할 때, 일할 때, 식사할 때, 여행 갈 때 등</p>
            <p>여러 일상 속에서</p>
            <p>우리는 항상 시간과 비용의 낭비를 경험해요.</p>
          </div>
        ) : (
          <div>
            <p>쇼핑할 때, 일할 때, 식사할 때, 여행 갈 때 등 여러 일상 속에서</p>
            <p>우리는 항상 시간과 비용의 낭비를 경험해요.</p>
          </div>
        )}
        <div>
          <p>지갑을 여는 곳이라면 어디든 </p>
          <p>손해 보지 않는 세상을 만들고 싶어요.</p>
        </div>
      </style.TextArea>
      <MotionSection isImage={true}>
        <style.LogoImg
          alt=""
          onClick={() => window.location.reload()}
          className="logo"
          src={`${process.env.PUBLIC_URL}/assets/new_web/full_width_white_logo.png`}
        />
      </MotionSection>
    </style.SecondPageWrapper>
  );
};

export default SecondPage;
