import React from 'react';
import {
    BgGray700,
    BgGray800,
    BgMonoBlack,
} from '../../_foundation/styleGuide/bgColors';
import { MonoWhite } from '../../_foundation/styleGuide/colors';
import CustomButton from './CustomButton';

const BlackButton = (props) => {
    return (
        <CustomButton
            {...props}
            textColor={MonoWhite}
            buttonColor={BgMonoBlack}
            hoverButtonColor={BgGray700}
            activeButtonColor={BgGray800}
        ></CustomButton>
    );
};

export default BlackButton;
