import React, { useEffect, useRef, useState } from "react";
import * as style from "./FourthPage.style";
import { useMediaQuery } from "react-responsive";
import { motion, transform, useAnimation, useInView } from "framer-motion";

export const PUBLIC_URL = process.env.PUBLIC_URL;

function MotionSection({ children, isImage, isMobile }) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView, mainControls]);

    return (
        <section ref={ref}>
            <motion.div
                variants={{
                    hidden: { opacity: 0, transform: transform([0, 100], [0, 360]) },
                    visible: { opacity: 1 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{
                    ease: "easeInOut",
                    duration: isImage ? 1 : 0.5,
                    delay: isImage ? 0.3 : isMobile ? 0.35 : 0.45,
                }}
            >
                {children}
            </motion.div>
        </section>
    );
}

const FourthPage = () => {
    const [isMobile, setIsMobile] = useState(false);

    const mobile = useMediaQuery({
        query: "(max-width:791px)",
    });

    useEffect(() => {
        if (mobile) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [mobile]);

    return (
        <style.FourthPageWrapper id="FourthPageWrapper">
            <style.SetItem>
                <style.VisionImage
                    src={isMobile ? `${process.env.PUBLIC_URL}/assets/fourth_page/positive_impact_m.png` : `${process.env.PUBLIC_URL}/assets/fourth_page/positive_impact.png`}
                    alt=""
                ></style.VisionImage>
                <MotionSection isMobile={isMobile} isImage={false}>
                    <style.VisionTextBox>
                        <style.PurpleText>We Focus on What Matters</style.PurpleText>
                        <style.MainText>
                            <p>세상의 꼭 필요한 서비스를 위해</p>
                        </style.MainText>
                        {isMobile ? (
                            <style.SubText>
                                <span>전보다 더 큰 가치를 전달할 수 있는 방법을 고민하며 세상에 없어서는 안 될 서비스로 거듭나기 위해 혁신적인 발전을 추구해요.</span>
                            </style.SubText>
                        ) : (
                            <style.SubText>
                                <p>전보다 더 큰 가치를 전달할 수 있는 방법을 고민하며</p>
                                <p>세상에 없어서는 안 될 서비스로 거듭나기 위해</p>
                                <p>혁신적인 발전을 추구해요.</p>
                            </style.SubText>
                        )}
                    </style.VisionTextBox>
                </MotionSection>
            </style.SetItem>
            <style.SetItemReverse>
                <style.VisionImage
                    src={isMobile ? `${process.env.PUBLIC_URL}/assets/fourth_page/inspiring_m.png` : `${process.env.PUBLIC_URL}/assets/fourth_page/inspiring.png`}
                    alt=""
                ></style.VisionImage>
                <MotionSection isMobile={isMobile} isImage={false}>
                    <style.VisionTextBox>
                        <style.PurpleText>We Inspire</style.PurpleText>
                        <style.MainText>
                            <p>모두가 닮고 싶고 따라하고 싶도록</p>
                        </style.MainText>
                        {isMobile ? (
                            <style.SubText>
                                <span>단순히 잘 하는 것을 넘어서는 성과를 지향하며 누가봐도 함께 일해보고 싶은 팀이자 만들어보고 싶은 제품이 될 수 있도록 노력해요.</span>
                            </style.SubText>
                        ) : (
                            <style.SubText>
                                <p>단순히 잘 하는 것을 넘어서는 성과를 지향하며</p>
                                <p>누가봐도 함께 일해보고 싶은 팀이자 만들어보고 싶은</p>
                                <p>제품이 될 수 있도록 노력해요.</p>
                            </style.SubText>
                        )}
                    </style.VisionTextBox>
                </MotionSection>
            </style.SetItemReverse>
            <style.SetItem>
                <style.VisionImage
                    src={isMobile ? `${process.env.PUBLIC_URL}/assets/fourth_page/game_changing_m.png` : `${process.env.PUBLIC_URL}/assets/fourth_page/game_changing.png`}
                    alt=""
                ></style.VisionImage>
                <MotionSection isMobile={isMobile} isImage={false}>
                    <style.VisionTextBox>
                        <style.PurpleText>We are Game-Changers</style.PurpleText>
                        <style.MainText>
                            <p>우리의 제품이</p>
                            <p>세상을 바꾼다는 일념으로</p>
                        </style.MainText>
                        {isMobile ? (
                            <style.SubText>
                                <span>스스로 문제를 찾고, 새롭게 정의하고, 도전적인 목표를 설정하여 주도적으로 일합니다. 누군가 걸어간 길이 아닌 새로운 길을 끊임없이 개척해요.</span>
                            </style.SubText>
                        ) : (
                            <style.SubText>
                                <p>스스로 문제를 찾고, 새롭게 정의하고, 도전적인 목표를</p>
                                <p>설정하여 주도적으로 일합니다. 누군가 걸어간 길이</p>
                                <p>아닌 새로운 길을 끊임없이 개척해요.</p>
                            </style.SubText>
                        )}
                    </style.VisionTextBox>
                </MotionSection>
            </style.SetItem>
        </style.FourthPageWrapper>
    );
};

export default FourthPage;
