import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

const useWidth = (init) => {
  const [browserWidth, setBrowserWidth] = useState(init);
  const resizeTimer = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (resizeTimer.current !== null) return;
      resizeTimer.current = setTimeout(() => {
        resizeTimer.current = null;
        setBrowserWidth(window.innerWidth);
      }, 200);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, [browserWidth]);

  return [browserWidth];
};

export default useWidth;
