import styled, { keyframes } from "styled-components";

export const PUBLIC_URL = process.env.PUBLIC_URL;

const fadeInUp = keyframes`
0% {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
to {
  opacity: 1;
  transform: translateZ(0);
}
`;

export const SecondPageWrapper = styled.div`
  cursor: default;

  justify-content: center;
  text-align: center;
  width: 100%;
  height: auto;

  background-size: cover;

  background-image: url(${`${PUBLIC_URL}/assets/new_web/main_image.png`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #555;
  margin-top: 169px;
  height: 250px;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Pretendard";
  font-size: 34px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  letter-spacing: -1px;
  @media (max-width: 791px) {
    margin-top: 140px;
    font-size: 18px;
    line-height: 26px;
    height: 196px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const LogoImg = styled.img`
  margin-top: 107px;
  width: 100%;
  height: width * (525/1920);
  margin-bottom: 120px;

  position: relative;
  animation: ${fadeInUp} 1s;

  @media (max-width: 791px) {
    margin-top: 90px;
    margin-bottom: 52px;
  }
`;
