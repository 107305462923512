import styled from "styled-components";

export const PUBLIC_URL = process.env.PUBLIC_URL;

export const MainSession = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  height: 680px;
  @media (max-width: 791px) {
    height: 500px;
  }
`;

export const ThirdPageWrapper = styled.div`
  width: 100%;
  height: 680px;
  justify-content: center;
  text-align: center;
  display: flex;

  background-attachment: ${window.navigator.userAgent.match(/iPhone|iPad|iPod/i)
    ? null
    : "fixed"};
  background-image: url(${`${PUBLIC_URL}/assets/new_web/static_image.png`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-position: 50% 50%;
  z-index: -3;
  @media (max-width: 791px) {
    height: 500px;
  }
`;

export const PurpleCover = styled.div`
  position: absolute;
  width: 100%;
  height: 680px;
  z-index: -1;
  background: linear-gradient(
    0deg,
    rgba(77, 4, 212, 0.6) 0%,
    rgba(77, 4, 212, 0.6) 100%
  );
  @media (max-width: 791px) {
    height: 500px;
  }
`;

export const TextArea = styled.div`
  color: white;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  letter-spacing: -1px;
  @media (max-width: 791px) {
    font-size: 22px;
    line-height: 30px;
  }
`;
