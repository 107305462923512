import { css } from 'styled-components';

export const Header48 = css`
    font-size: 48px;
    font-family: 'Pretendard Bold';
    line-height: 60px;
    font-weight: 700;
    font-style: normal;
`;

export const Header40 = css`
    font-size: 40px;
    font-family: 'Pretendard Bold';
    line-height: 50px;
    font-weight: 700;
    font-style: normal;
`;

export const Header36 = css`
    font-size: 36px;
    font-family: 'Pretendard Bold';
    line-height: 46px;
    font-weight: 700;
    font-style: normal;
`;

export const Header32 = css`
    font-size: 32px;
    font-family: 'Pretendard Bold';
    line-height: 42px;
    font-weight: 700;
    font-style: normal;
`;

export const Header30 = css`
    font-size: 30px;
    font-family: 'Pretendard Bold';
    line-height: 39px;
    font-weight: 700;
    font-style: normal;
`;

export const Header24 = css`
    font-size: 24px;
    font-family: 'Pretendard Bold';
    line-height: 32px;
    font-weight: 700;
    font-style: normal;
`;

export const Header22 = css`
    font-size: 22px;
    font-family: 'Pretendard Bold';
    line-height: 30px;
    font-weight: 700;
    font-style: normal;
`;

export const Header20 = css`
    font-size: 20px;
    font-family: 'Pretendard Bold';
    line-height: 28px;
    font-weight: 700;
    font-style: normal;
`;

export const Header18 = css`
    font-size: 18px;
    font-family: 'Pretendard Bold';
    line-height: 26px;
    font-weight: 700;
    font-style: normal;
`;

export const Header16 = css`
    font-size: 16px;
    font-family: 'Pretendard Bold';
    line-height: 24px;
    font-weight: 700;
    font-style: normal;
`;

export const Header15 = css`
    font-size: 15px;
    font-family: 'Pretendard Bold';
    line-height: 22px;
    font-weight: 700;
    font-style: normal;
`;

export const Header14 = css`
    font-size: 14px;
    font-family: 'Pretendard Bold';
    line-height: 20px;
    font-weight: 700;
    font-style: normal;
`;

export const Header13 = css`
    font-size: 13px;
    font-family: 'Pretendard Bold';
    line-height: 18px;
    font-weight: 700;
    font-style: normal;
`;

export const Header12 = css`
    font-size: 12px;
    font-family: 'Pretendard Bold';
    line-height: 18px;
    font-weight: 700;
    font-style: normal;
`;

export const Header10 = css`
    font-size: 10px;
    font-family: 'Pretendard Bold';
    line-height: 14px;
    font-weight: 700;
    font-style: normal;
`;

//Title(500)
export const Title20 = css`
    font-size: 20px;
    font-family: 'Pretendard Medium';
    line-height: 28px;
    font-weight: 500;
    font-style: normal;
`;

export const Title19 = css`
    font-size: 19px;
    font-family: 'Pretendard Medium';
    line-height: 27px;
    font-weight: 500;
    font-style: normal;
`;

export const Title18 = css`
    font-size: 18px;
    font-family: 'Pretendard Medium';
    line-height: 26px;
    font-weight: 500;
    font-style: normal;
`;

export const Title16 = css`
    font-size: 16px;
    font-family: 'Pretendard Medium';
    line-height: 24px;
    font-weight: 500;
    font-style: normal;
`;

export const Title15 = css`
    font-size: 15px;
    font-family: 'Pretendard Medium';
    line-height: 22px;
    font-weight: 500;
    font-style: normal;
`;

export const Title14 = css`
    font-size: 14px;
    font-family: 'Pretendard Medium';
    line-height: 20px;
    font-weight: 500;
    font-style: normal;
`;

export const Title13 = css`
    font-size: 13px;
    font-family: 'Pretendard Medium';
    line-height: 18px;
    font-weight: 500;
    font-style: normal;
`;

export const Title12 = css`
    font-size: 12px;
    font-family: 'Pretendard Medium';
    line-height: 18px;
    font-weight: 500;
    font-style: normal;
`;

export const Title11 = css`
    font-size: 11px;
    font-family: 'Pretendard Medium';
    line-height: 16px;
    font-weight: 500;
    font-style: normal;
`;

export const Title10 = css`
    font-size: 10px;
    font-family: 'Pretendard Medium';
    line-height: 14px;
    font-weight: 500;
    font-style: normal;
`;

//Body(400)
export const Body20 = css`
    font-size: 20px;
    font-family: 'Pretendard Regular';
    line-height: 28px;
    font-weight: 400;
    font-style: normal;
`;

export const Body19 = css`
    font-size: 19px;
    font-family: 'Pretendard Regular';
    line-height: 27px;
    font-weight: 400;
    font-style: normal;
`;

export const Body18 = css`
    font-size: 18px;
    font-family: 'Pretendard Regular';
    line-height: 26px;
    font-weight: 400;
    font-style: normal;
`;
export const Body16 = css`
    font-size: 16px;
    font-family: 'Pretendard Regular';
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
`;
export const Body15 = css`
    font-size: 15px;
    font-family: 'Pretendard Regular';
    line-height: 22px;
    font-weight: 400;
    font-style: normal;
`;
export const Body14 = css`
    font-size: 14px;
    font-family: 'Pretendard Regular';
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
`;
export const Body13 = css`
    font-size: 13px;
    font-family: 'Pretendard Regular';
    line-height: 18px;
    font-weight: 400;
    font-style: normal;
`;
export const Body12 = css`
    font-size: 12px;
    font-family: 'Pretendard Regular';
    line-height: 18px;
    font-weight: 400;
    font-style: normal;
`;
export const Body11 = css`
    font-size: 11px;
    font-family: 'Pretendard Regular';
    line-height: 16px;
    font-weight: 400;
    font-style: normal;
`;
export const Body10 = css`
    font-size: 10px;
    font-family: 'Pretendard Regular';
    line-height: 14px;
    font-weight: 400;
    font-style: normal;
`;

//--------------------------

export const ContentsBody20 = css`
    font-size: 20px;
    font-family: 'Pretendard Regular';
    line-height: 34.5px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
`;

export const ContentsBody19 = css`
    font-size: 19px;
    font-family: 'Pretendard Regular';
    line-height: 32.5px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.1px;
`;

export const ContentsBody18 = css`
    font-size: 18px;
    font-family: 'Pretendard Regular';
    line-height: 30.5px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.15px;
`;

export const ContentsBody17 = css`
    font-size: 17px;
    font-family: 'Pretendard Regular';
    line-height: 28.5px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.2px;
`;

export const ContentsBody16 = css`
    font-size: 16px;
    font-family: 'Pretendard Regular';
    line-height: 26.5px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.25px;
`;

export const ContentsBody15 = css`
    font-size: 15px;
    font-family: 'Pretendard Regular';
    line-height: 24.5px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.35px;
`;

export const ContentsBody14 = css`
    font-size: 14px;
    font-family: 'Pretendard Regular';
    line-height: 22.5px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
`;

export const ContentsBody13 = css`
    font-size: 13px;
    font-family: 'Pretendard Regular';
    line-height: 20.5px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.45px;
`;

export const ContentsHeader20 = css`
    font-size: 20px;
    font-family: 'Pretendard Bold';
    line-height: 34.5px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
`;

export const ContentsHeader19 = css`
    font-size: 19px;
    font-family: 'Pretendard Bold';
    line-height: 32.5px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.1px;
`;

export const ContentsHeader18 = css`
    font-size: 18px;
    font-family: 'Pretendard Bold';
    line-height: 30.5px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.15px;
`;

export const ContentsHeader17 = css`
    font-size: 17px;
    font-family: 'Pretendard Bold';
    line-height: 28.5px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.2px;
`;

export const ContentsHeader16 = css`
    font-size: 16px;
    font-family: 'Pretendard Bold';
    line-height: 26.5px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.25px;
`;

export const ContentsHeader15 = css`
    font-size: 15px;
    font-family: 'Pretendard Bold';
    line-height: 24.5px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.35px;
`;

export const ContentsHeader14 = css`
    font-size: 14px;
    font-family: 'Pretendard Bold';
    line-height: 22.5px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.4px;
`;

export const ContentsHeader13 = css`
    font-size: 13px;
    font-family: 'Pretendard Bold';
    line-height: 20.5px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.45px;
`;
