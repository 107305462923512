import { useEffect, useState } from "react";
import { BgMonoWhite, BgPurple700 } from "../_foundation/styleGuide/bgColors";
import { MonoBlack, MonoWhite } from "../_foundation/styleGuide/colors";
import {
  Header18,
  Header30,
  Title18,
} from "../_foundation/styleGuide/typography";
import styled, { css, keyframes } from "styled-components";

const QrPage = () => {
  const [isRotating, setIsRotating] = useState(true);

  useEffect(() => {
    // 3바퀴 돌리고 자동으로 멈추도록 타이머를 설정
    const timer = setTimeout(() => {
      setIsRotating(false);
    }, 800); // 3초 후에 애니메이션 멈춤

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <StyledQrCardPage backgroundPath={`/assets/qrCardBackground.jpg`}>
      <RotatingQrCard isRotating={isRotating}>
        <div className="title">
          사공사 앱에서
          <br />더 많은 상품의 최저가와
          <br />
          가격 알림까지 받아보세요!
        </div>
        <div className="subTitle">QR 코드를 스캔해서 앱을 설치해 보세요.</div>

        <img className="qrImg" src={`/assets/qr_50.png`} />
        <div
          data-section-id="qr"
          data-type-id="button"
          data-content-id="qr_button"
          onClick={() => (window.location.href = "/")}
          className="button"
        >
          확인
        </div>
      </RotatingQrCard>
    </StyledQrCardPage>
  );
};

const StyledQrCardPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${({ backgroundPath }) => backgroundPath});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  position: relative;
  background-size: cover;
  background-position: center;
`;

const rotateY3Turns = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(1080deg);
  }
`;

const RotatingQrCard = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  width: 420px;
  height: 616px;
  border-radius: 40px;
  border: 2.5px solid #fff;

  border-radius: 40px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px -14px 100px 0px rgba(255, 255, 255, 0.1) inset;
  backdrop-filter: blur(10px);

  ${({ isRotating }) =>
    isRotating &&
    css`
      animation: ${rotateY3Turns} 2.4s linear infinite;
    `}

  .title {
    margin-top: 51px;
    ${Header30}
  }

  .subTitle {
    margin-top: 16px;
    ${Title18}
  }

  .qrImg {
    margin-top: 30px;
    width: 220px;
    height: 220px;
  }

  .button {
    margin-top: 60px;

    display: flex;
    width: 340px;
    padding: 15px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    cursor: pointer;
    ${Header18}
    ${MonoWhite}
    ${BgPurple700}
  }

  .appIntro {
    margin-top: 16px;

    display: flex;
    width: 340px;
    padding: 15px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    cursor: pointer;
    ${Header18}
    ${BgMonoWhite};
    ${MonoBlack};
  }
`;

export default QrPage;
