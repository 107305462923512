import React, { useEffect, useState } from "react";
import * as style from "./TeamIntroScreen.style";
import { useMediaQuery } from "react-responsive";
import BlackButton from "./Button/BlackButton";

export const PUBLIC_URL = process.env.PUBLIC_URL;

const TeamIntroScreen = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const mobile = useMediaQuery({
    query: "(max-width:791px)",
  });

  useEffect(() => {
    if (mobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [mobile]);

  useEffect(() => {
    if (showProfile) {
      setShowProfile(true);
    } else {
      setTimeout(() => {
        setShowProfile(false);
      }, 400);
    }
  }, [showProfile]);

  const teamList = [
    {
      name: "권형준",
      position: "CEO",
      mainText: "혁신을 넘어 혁명적인 변화",
      subText:
        "단순하게 좋은, 더 나은 제품을 넘어 모든 것을 바꾸는 아이디어와 경험을 만드는 팀과 함께합니다.",
      image: `${process.env.PUBLIC_URL}/assets/profile/hj.png`,
    },
    {
      name: "정세현",
      position: "CTO",
      mainText: "마음을 꿰뚫는 기술",
      subText:
        "지속적인 기술 혁신을 통해 사용자들의 최상의 만족을 실현하고자 합니다.",
      image: `${process.env.PUBLIC_URL}/assets/profile/sh.png`,
    },
    {
      name: "강성구",
      position: "Product Design Lead",
      mainText: "이전의 틀을 깨는 제품을 위해",
      subText:
        "어떤 문제든 집요하게 뜯어보고 누구에게나 쉽고 모두에게 반드시 필요한 제품을 만들고 싶습니다.",
      image: `${process.env.PUBLIC_URL}/assets/profile/sg.png`,
    },
    {
      name: "조주선",
      position: "Frontend Developer",
      mainText: "끊임없는 고민, 새로운 가능성",
      subText:
        "고민을 통해 가능성을 발견하고 세상을 바꿀 수 있는 기회로 만들고 싶습니다.",
      image: `${process.env.PUBLIC_URL}/assets/profile/js.png`,
    },
  ];

  return (
    <style.TeamIntroScreenWrapper id="TeamIntroScreenWrapper">
      <style.IntroduceBox>
        <img
          className="boxImg"
          src={`${process.env.PUBLIC_URL}/assets/new_web/metal_chat.png`}
          alt=""
        />
        <p className="teamText">Our Team</p>
        {isMobile ? (
          <>
            <p className="boxText">책임감을 가지고 주도적으로</p>
            <p className="boxText">하나의 목표를 위해 자유롭게 일해요.</p>
          </>
        ) : (
          <p className="boxText">
            책임감을 가지고 주도적으로 하나의 목표을 위해 자유롭게 일해요.
          </p>
        )}
        {/* {isMobile ? } */}
      </style.IntroduceBox>
      <style.IntroTeams>
        <div className="teamsWrap">
          {teamList.map((team, idx) => (
            <div className="team" key={idx}>
              <img src={team.image} className="icon" alt="" />
              <div className="textBox">
                <p className="personName">{team.name}</p>
                <p className="personRoll">{team.position}</p>
                <p className="personMainText">{team.mainText}</p>
                <p className="personSubText">{team.subText}</p>
              </div>
            </div>
          ))}
        </div>
      </style.IntroTeams>
    </style.TeamIntroScreenWrapper>
  );
};

export default TeamIntroScreen;
