import styled from "styled-components";
import {
  Body16,
  Body18,
  Header20,
  Header24,
} from "../_foundation/styleGuide/typography";

export const PUBLIC_URL = process.env.PUBLIC_URL;

export const TeamIntroScreenWrapper = styled.div`
  cursor: default;

  justify-content: center;
  text-align: center;
  width: 100%;

  background: #f9f9f9;
  padding-bottom: 250px;

  @media (max-width: 791px) {
    height: auto;
    padding-bottom: 60px;
  }
`;

export const IntroduceBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 200px;
  @media (max-width: 791px) {
    padding-top: 60px;
    padding-bottom: 32px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .boxImg {
    width: 260px;
    @media (max-width: 791px) {
      width: 120px;
    }
  }
  .teamText {
    color: #7468ff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 62px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: -1px;
    @media (max-width: 791px) {
      font-size: 32px;
      line-height: 42px;
      letter-spacing: -1px;
      padding-bottom: 8px;
    }
  }

  .boxText {
    color: #7468ff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -1px;
    @media (max-width: 791px) {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -1px;
    }
  }
`;

export const MobileIntroTeams = styled.div`
  .slideFadeInDropdownList {
    height: auto;
    opacity: 1;
    overflow: visible;

    transition-duration: 600ms;
    transition-timing-function: cubic-bezier(0.31, 0.27, 0.15, 0.99);
    transition-delay: 0s;
  }
  .slideFadeOutDropdownList {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition-duration: 600ms;
    transition-timing-function: cubic-bezier(0.31, 0.27, 0.15, 0.99);
    transition-delay: 0s;
  }
`;

export const IntroTeams = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .teamsWrap {
    width: 1200px;
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 36px 24px;

    @media (max-width: 1508px) {
      width: calc(1508px - 282px - 24px);
    }

    @media (max-width: 1200px) {
      width: calc(1508px - 282px - 24px - 282px - 24px);
    }

    @media (max-width: 895px) {
      width: calc(1508px - 282px - 24px - 282px - 24px - 282px - 24px);
    }

    @media (max-width: 791px) {
      display: flex;
      flex-direction: column;
      margin-top: 60px;
      padding: 0 20px;
      gap: 24px;
      width: 518px;
      justify-content: center;
    }

    @media (max-width: 558px) {
      width: calc(518px - 152px - 31px);
    }

    .team {
      width: 282px;
      height: 426px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      background-color: white;
      text-align: left;

      @media (max-width: 791px) {
        width: 302px;
      }

      .icon {
        width: 160px;
        height: 160px;

        @media (max-width: 791px) {
          width: 140px;
          height: 140px;
        }
      }

      .textBox {
        text-align: left;
        padding-left: 22px;
        padding-right: 22px;
        @media (max-width: 791px) {
          padding-left: 32px;
          padding-right: 32px;
        }
      }

      .personName {
        padding-top: 24px;
        ${Header24}
      }
      .personRoll {
        padding-top: 2px;
        ${Body16}
      }
      .personMainText {
        padding-top: 18px;
        color: #858585;
        ${Header20}
      }
      .personSubText {
        padding: 8px 0 0 0;
        color: #858585;
        ${Body18}
        word-break: keep-all;
      }
    }
  }
`;
