import styled from "styled-components";

export const MainWrapper = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 68px;
  background-color: ${({ headerOpacity, opacity }) =>
    `rgba(255, 255, 255, ${headerOpacity})`};
  transition: background-color 0.1s ease;

  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 00px;
  @media (max-width: 791px) {
    height: 60px;
  }
  .logo {
    cursor: pointer;
    width: 146px;
    height: 40px;
  }
`;

export const LogoImg = styled.img`
  padding-left: 30px;

  @media (max-width: 791px) {
    padding-left: 20px;
  }
`;

export const RightSection = styled.div`
  padding-right: 30px;
  display: flex;
  gap: 32px;

  @media (max-width: 791px) {
    padding-right: 20px;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    font-size: 15px;
    line-height: 21px;
  }

  .hamburger {
    width: 24px;
    height: 24px;
  }

  .button {
    /* margin-top: 60px; */

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 80px;
    height: 48px;
    border-radius: 16px;
    background-color: black;
    color: var(--mono-white, #fff);
    text-align: center;

    font-size: 15px;
    font-style: normal;
    font-weight: 700;

    line-height: 28px;

    @media (max-width: 791px) {
      width: 100%;
      margin-top: 100px;
    }
  }
  .img {
  }
`;
