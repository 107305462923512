import styled from "styled-components";

export const PUBLIC_URL = process.env.PUBLIC_URL;

export const FourthPageWrapper = styled.div`
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 200px 0 275px 0;
  text-align: center;
  width: 100%;
  height: auto;
  background: #f2f2f2;

  gap: 300px;
  @media (max-width: 791px) {
    padding-top: 70px;
    padding-bottom: 70px;
    gap: 80px;
  }
`;

export const SetItem = styled.div`
  display: flex;
  height: auto;
  gap: 78px;
  @media (max-width: 791px) {
    flex-direction: column;
    align-items: center;
    gap: 32px;
    height: auto;
  }
`;

export const SetItemReverse = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 78px;
  height: auto;
  @media (max-width: 791px) {
    flex-direction: column;
    align-items: center;
    gap: 32px;
    height: auto;
  }
`;

export const VisionImage = styled.img`
  border-radius: 40px;
  width: 550px;
  height: 700px;
  @media (max-width: 791px) {
    width: calc(100vw - 40px);
    height: calc((100vw - 40px) * (426 / 335));
  }
`;

export const VisionTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-left: 20px;
  padding-right: 20px;

  position: relative;
`;

export const PurpleText = styled.span`
  color: var(--Color-Purple-800, #6b1aff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Montserrat";
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -1px;
  @media (max-width: 791px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const MainText = styled.span`
  color: #000;
  padding-top: 30px;
  padding-bottom: 40px;
  text-align: left;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -2px;
  @media (max-width: 791px) {
    padding-top: 20px;
    padding-bottom: 16px;
    font-size: 24px;
    line-height: 34px;
  }
`;

export const SubText = styled.span`
  color: #4d4d4d;
  text-align: left;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Pretendard";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -2px;
  word-break: keep-all;
  @media (max-width: 791px) {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
  }
`;
