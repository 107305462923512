import "../App.css";
// import useWidth from "./hook/useWidth";
import WebHeader from "../components/WebHeader";
import FirstPage from "../components/FirstPage";
import SecondPage from "../components/SecondPage";
import ThirdPage from "../components/ThirdPage";
import FourthPage from "../components/FourthPage";
import InfiniteScrollScreen from "../components/InfiniteScrollScreen";
import AppInstallationBanner from "../components/AppInstallationBanner";
import Footer from "../components/Footer";
import TeamIntroScreen from "../components/TeamIntroScreen";
import ApplyButtonScreen from "../components/ApplyButtonScreen";
import ScreenShotPage from "../components/ScreenShotPage";
const MainPage = () => {
  return (
    <>
      <WebHeader />
      <FirstPage />
      <SecondPage />
      <ThirdPage />
      <FourthPage />
      <TeamIntroScreen />
      <ApplyButtonScreen />
      <ScreenShotPage />
      <InfiniteScrollScreen />
      <AppInstallationBanner />
      <Footer />
    </>
  );
};

export default MainPage;
