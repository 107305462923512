import styled, { css, keyframes } from "styled-components";

export const PUBLIC_URL = process.env.PUBLIC_URL;

export const FirstPageWrapper = styled.div`
  width: 100%;
  display: flex;
  cursor: default;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const ChangeTextArea = styled.div`
  width: 100%;
  height: 100%;
  /* padding-top: 287px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 791px) {
    padding-top: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const fadeIn = keyframes`
  from {
    color: white
  }
  to {
    color: #6B1AFF;
  }
`;

export const fadeOut = keyframes`
  from {
    color: #6B1AFF;
  }
  to {
    color: white;
  }
`;

export const BackVideo = styled("video")({
  position: "absolute",
  width: "100vw",
  objectFit: "cover",
  height: "auto",
  zIndex: "-1",
  overflow: "hidden",
  "@media screen and (max-aspect-ratio: 1219/685)": {
    width: "100vw",
    height: "100vh",
  },
});

export const MainText = styled.div`
  font-family: "Montserrat Bold";
  color: white;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
   font-size: 4.6875vw; /* 1920 기준으로 90px */
  font-style: normal;
  font-weight: 600;
  line-height: 5.2083vw; /* 1920 기준으로 100px */
  letter-spacing: -0.10417vw; /* 1920 기준으로 -2px */
  @media (max-width: 791px) {
    padding-top: 80px;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 90px;
  }
  .staticText {
  }
  .changeText {
    animation: ${({ animationPlaying }) =>
      animationPlaying
        ? css`
            ${fadeIn} 0.5s forwards
          `
        : css`
            /* ${fadeOut} 0.5s forwards */
          `};
  }
  .purpleText {
    color: #6b1aff;
  }
`;
