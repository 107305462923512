import React, { useEffect, useState } from "react";

import * as style from "./FirstPage.style";
import { useMediaQuery } from "react-responsive";
import backVideo from "../video/coverVideo.mp4";
import BlackButton from "./Button/BlackButton";
import { redirectToAppStore } from "../function/redirectToAppStore";

const FirstPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  const mobile = useMediaQuery({
    query: "(max-width:791px)",
  });

  useEffect(() => {
    if (mobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [mobile]);

  const [tmp, setTmp] = useState(true);

  const [scrollMoveControll, setScrollMoveControll] = useState(0);

  const [animationPlaying, setAnimationPlaying] = useState(false);

  useEffect(() => {
    const topBanner = document.querySelector("#FirstPageWrapper");
    if (!isMobile) {
      function handleMouseWheel(event) {
        if (tmp) {
          event.preventDefault();
          if (scrollMoveControll !== event.deltaY) {
            setScrollMoveControll(event.deltaY);
            if (event.deltaY >= 30) {
              setAnimationPlaying(true);

              setTimeout(() => {
                setTmp(false);
              }, 500);
            }
          }
        } else {
          topBanner.scrollTo(0, 0);
        }
        if (scrollMoveControll !== event.deltaY) {
          setScrollMoveControll(event.deltaY);
          if (event.deltaY <= -30) {
            setAnimationPlaying(true);
          }
        }
      }

      topBanner.addEventListener("mousewheel", handleMouseWheel);

      return () => {
        topBanner.removeEventListener("mousewheel", handleMouseWheel);
      };
    }
  }, [tmp, scrollMoveControll, isMobile]);

  return (
    <style.FirstPageWrapper id="FirstPageWrapper">
      <style.BackVideo
        muted
        autoPlay
        loop
        playsInline
        src={backVideo}
      ></style.BackVideo>
      <style.ChangeTextArea>
        <style.MainText animationPlaying={animationPlaying}>
          <span className={isMobile ? "purpleText" : "changeText"}>
            Innovating
          </span>
          {isMobile ? <br /> : null}
          <span className="staticText">
            {isMobile ? "the way" : " the way"}
          </span>
          <br />
          <span className="staticText">people </span>
          <span className={isMobile ? "purpleText" : "changeText"}>shop</span>
          <br />
          <span className={isMobile ? "purpleText" : "changeText"}>
            Changing
          </span>
          {isMobile ? <br /> : null}
          <span className="staticText">
            {isMobile ? "the way" : " the way"}
          </span>
          <br />
          <span className="staticText">people </span>
          <span className={isMobile ? "purpleText" : "changeText"}>think</span>
        </style.MainText>
        {isMobile ? (
          <BlackButton
            onClick={() => {
              isMobile ? redirectToAppStore() : (window.location.href = "/qr");
            }}
            text="앱 다운로드"
            size="big"
            width={!isMobile ? "180px" : "calc(100% - 32px)"}
          />
        ) : null}
      </style.ChangeTextArea>
    </style.FirstPageWrapper>
  );
};

export default FirstPage;
