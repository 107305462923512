import "./App.css";
import styled from "styled-components";
import AppInstallationBanner from "./components/AppInstallationBanner";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainPage from "./page/MainPage";
import QrPage from "./page/QrPage";
export const PUBLIC_URL = process.env.PUBLIC_URL;

function App() {
  // const [browserWidth] = useWidth(window.innerWidth);

  return (
    <>
      <Router>
        <StyledApp>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/qr" element={<QrPage />} />
          </Routes>
        </StyledApp>
      </Router>
    </>
  );
}

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  user-select: none;
  overflow-x: hidden;
`;

export default App;
