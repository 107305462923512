import React from "react";

import * as style from "./ThirdPage.style";

const ThirdPage = () => {


  return (
    <style.MainSession>
      <style.ThirdPageWrapper>
        <style.PurpleCover />
        <style.TextArea>
          <p>누구나 필요로 하는 서비스를</p>
          <p>누구보다 멋지게</p>
          <p>누가 봐도 다르게 만들어 가고 있어요.</p>
        </style.TextArea>
      </style.ThirdPageWrapper>
    </style.MainSession>
  );
};

export default ThirdPage;
