import React from "react";
import styled from "styled-components";

const SideMenu = ({ testRef, mobileMenu, setMobileMenu }) => {
  return (
    <StyledSidebar>
      <div ref={testRef} className={`sidebar ${mobileMenu ? "open" : ""}`}>
        <div className="menus">
          <a
            href="https://www.wanted.co.kr/company/35924"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "black" }}
          >
            <h2 className="menu apply">지원하기</h2>
          </a>
          <h2
            onClick={() =>
              (window.location.href = "mailto:contact@postlabs.io")
            }
            className="menu inquiry"
          >
            문의하기
          </h2>
        </div>
        <img
          alt=""
          onClick={() => setMobileMenu(false)}
          className="close"
          src={`${process.env.PUBLIC_URL}/assets/close.png`}
        />
      </div>
      {mobileMenu && (
        <div className="overlay" onClick={() => setMobileMenu(false)}></div>
      )}
    </StyledSidebar>
  );
};

const StyledSidebar = styled.div`
  .sidebar {
    position: fixed;
    top: 0;
    right: -300px;
    width: 250px;
    height: 100vh;
    background-color: white;
    transition: right 0.3s ease;
    z-index: 9999;
  }

  .menus {
    display: flex;
    flex-direction: column;
    gap: 36px;

    margin-top: 80px;
    margin-left: 20px;

    .menu {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .sidebar.open {
    right: 0;
  }

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4); /* 투명도가 있는 회색 배경 */
    z-index: 999; /* 사이드바 뒷쪽 뷰포트를 가리키는 요소의 z-index */
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
  }
`;

export default SideMenu;
