import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useWidth from "../hook/useWidth";
import { redirectToAppStore } from "../function/redirectToAppStore";
import { useMediaQuery } from "react-responsive";

const AppInstallationBanner = () => {
  const [browserWidth] = useWidth(window.innerWidth);

  const [isMobile, setIsMobile] = useState(false);

  const mobile = useMediaQuery({
    query: "(max-width:791px)",
  });

  useEffect(() => {
    if (mobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [mobile]);

  return (
    <StyledAppInstallationMiniBanner>
      <h1 className="mainText">
        사공사 앱에서 전혀 다른
        <br /> 쇼핑을 경험해 보세요!
      </h1>
      {browserWidth > 791 ? (
        <img
          alt=""
          className="qr"
          src={`${process.env.PUBLIC_URL}/assets/qr_border.png`}
        />
      ) : (
        <h1
          onClick={() => {
            isMobile ? redirectToAppStore() : (window.location.href = "/qr");
          }}
          className="button"
        >
          앱 설치하기
        </h1>
      )}
    </StyledAppInstallationMiniBanner>
  );
};

const StyledAppInstallationMiniBanner = styled.div`
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 300px;
  background: var(--color-purple-700, #7b33ff);
  color: var(--mono-white, #fff);

  @media (max-width: 791px) {
    margin-top: 100px;

    height: 260px;
    text-align: center;
    flex-direction: column;
  }

  .mainText {
    font-size: 40px;
    line-height: 50px;
    width: 606px;

    @media (max-width: 791px) {
      font-size: 24px;
      line-height: 32px;
      width: 100%;
    }
  }

  .qr {
    margin-left: 30px;
    width: 156px;
    height: 156px;
  }

  .button {
    margin-top: 20px;

    padding: 12px 16px;
    background-color: white;
    color: #7b33ff;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export default AppInstallationBanner;
