import React, { useEffect, useState } from "react";
import * as style from "./ApplyButtonScreen.style";
import { useMediaQuery } from "react-responsive";

const ApplyButtonScreen = () => {
  const [isMobile, setIsMobile] = useState(false);

  const mobile = useMediaQuery({
    query: "(max-width:791px)",
  });

  useEffect(() => {
    if (mobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [mobile]);

  return (
    <>
      <style.ScrollImgBox>
        <div
          className="scroll imgBox"
          style={{ "--time": isMobile ? "400s" : "1200s" }}
        >
          <div>
            {[...Array(30)].map((_, index) => (
              <React.Fragment key={index}>
                <img src="/assets/scroll_image/image_1.png" alt="" />
                <img src="/assets/scroll_image/image_2.png" alt="" />
                <img src="/assets/scroll_image/image_3.png" alt="" />
                <img src="/assets/scroll_image/image_4.png" alt="" />
                <img src="/assets/scroll_image/image_5.png" alt="" />
                <img src="/assets/scroll_image/image_6.png" alt="" />
              </React.Fragment>
            ))}
          </div>
        </div>
      </style.ScrollImgBox>
      <style.ApplyButtonScreenWrapper id="ApplyButtonScreenWrapper">
        <style.logoImg
          src={`${process.env.PUBLIC_URL}/assets/new_web/logo_apply_white.png`}
        />
        <a
          href="https://www.wanted.co.kr/company/35924"
          target="_blank"
          rel="noopener noreferrer"
          textDecoration="none"
          color="inherit"
          style={{ textDecoration: "none", color: "black" }}
        >
          <style.ApplyButton
            src={`${process.env.PUBLIC_URL}/assets/apply_outlink.png`}
          />
        </a>
      </style.ApplyButtonScreenWrapper>
    </>
  );
};

export default ApplyButtonScreen;
