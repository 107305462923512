import { css } from 'styled-components';

//Mono
export const BgMonoBlack = css`
    background-color: black;
`;
export const BgMonoWhite = css`
    background-color: white;
`;

//Grayscale
export const BgGray900 = css`
    background-color: #111111;
`;
export const BgGray800 = css`
    background-color: #303030;
`;
export const BgGray700 = css`
    background-color: #555555;
`;
export const BgGray600 = css`
    background-color: #858585;
`;
export const BgGray500 = css`
    background-color: #999999;
`;
export const BgGray400 = css`
    background-color: #c2c2c2;
`;
export const BgGray300 = css`
    background-color: #d6d6d6;
`;
export const BgGray200 = css`
    background-color: #ebebeb;
`;
export const BgGray100 = css`
    background-color: #f5f5f5;
`;
export const BgGray050 = css`
    background-color: #f8f8f8;
`;
export const BgGray010 = css`
    background-color: #fafafa;
`;

//purple
export const BgPurple900 = css`
    background-color: #5a00ff;
`;

export const BgPurple800 = css`
    background-color: #6b1aff;
`;

export const BgPurple700 = css`
    background-color: #7b33ff;
`;

export const BgPurple600 = css`
    color: #8b4dff;
`;

export const BgPurple500 = css`
    background-color: #9c66ff;
`;

export const BgPurple400 = css`
    background-color: #ac80ff;
`;

export const BgPurple300 = css`
    background-color: #bd99ff;
`;

export const BgPurple200 = css`
    background-color: #cdb2ff;
`;

export const BgPurple100 = css`
    background-color: #deccff;
`;

export const BgPurple050 = css`
    background-color: #eee5ff;
`;

//green
export const BgGreen900 = css`
    background-color: #15bd66;
`;

export const BgGreen800 = css`
    background-color: #44ca85;
`;

export const BgGreen700 = css`
    background-color: #5bd194;
`;

export const BgGreen600 = css`
    background-color: #73d7a3;
`;

export const BgGreen500 = css`
    background-color: #8adeb2;
`;

export const BgGreen400 = css`
    background-color: #a1e5c2;
`;

export const BgGreen300 = css`
    background-color: #b9ebd1;
`;

export const BgGreen200 = css`
    background-color: #d0f2e0;
`;

export const BgGreen100 = css`
    background-color: #e8f8f0;
`;

//red

export const BgRed900 = css`
    background-color: #ff334b;
`;

export const BgRed800 = css`
    background-color: #ff5c6f;
`;

export const BgRed700 = css`
    background-color: #ff7081;
`;

export const BgRed600 = css`
    background-color: #ff8593;
`;

export const BgRed500 = css`
    background-color: #ff99a5;
`;

export const BgRed400 = css`
    background-color: #ffadb7;
`;

export const BgRed300 = css`
    background-color: #ffc2c9;
`;

export const BgRed200 = css`
    background-color: #ffd6db;
`;

export const BgRed100 = css`
    background-color: #ffebed;
`;

//yellow
export const BgYellow900 = css`
    background-color: #ffc53d;
`;

export const BgYellow800 = css`
    background-color: #ffd164;
`;
export const BgYellow700 = css`
    background-color: #ffd677;
`;
export const BgYellow600 = css`
    background-color: #ffdc8b;
`;
export const BgYellow500 = css`
    background-color: #ffe29e;
`;
export const BgYellow400 = css`
    background-color: #ffe8b1;
`;
export const BgYellow300 = css`
    background-color: #ffeec5;
`;
export const BgYellow200 = css`
    background-color: #fff3d8;
`;
export const BgYellow100 = css`
    background-color: #fff9ec;
`;

//blue

export const BgBlue900 = css`
    background-color: #1a9cff;
`;

export const BgBlue800 = css`
    background-color: #48b0ff;
`;

export const BgBlue700 = css`
    background-color: #5fbaff;
`;

export const BgBlue600 = css`
    background-color: #76c4ff;
`;

export const BgBlue500 = css`
    background-color: #8ccdff;
`;

export const BgBlue400 = css`
    background-color: #a3d7ff;
`;

export const BgBlue300 = css`
    background-color: #bae1ff;
`;

export const BgBlue200 = css`
    background-color: #d1ebff;
`;

export const BgBlue100 = css`
    background-color: #e8f5ff;
`;

//deepBlue

export const BgDeepBlue900 = css`
    background-color: #4270ed;
`;

export const BgDeepBlue800 = css`
    background-color: #688df1;
`;

export const BgDeepBlue700 = css`
    background-color: #7b9bf2;
`;

export const BgDeepBlue600 = css`
    background-color: #8ea9f4;
`;

export const BgDeepBlue500 = css`
    background-color: #a0b7f6;
`;

export const BgDeepBlue400 = css`
    background-color: #b3c6f8;
`;

export const BgDeepBlue300 = css`
    background-color: #c6d4fa;
`;

export const BgDeepBlue200 = css`
    background-color: #d9e2fb;
`;

export const BgDeepBlue100 = css`
    background-color: #ecf1fd;
`;
