import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BgGray200 } from '../../_foundation/styleGuide/bgColors';
import { Gray400 } from '../../_foundation/styleGuide/colors';
import {
    Header14,
    Header15,
    Header16,
    Header18,
} from '../../_foundation/styleGuide/typography';

const CustomButton = (props) => {
    const { text, leftIcon, rightIcon } = props;
    return (
        <StyledButton {...props}>
            {leftIcon}
            {text}
            {rightIcon}
        </StyledButton>
    );
};

const StyledButton = styled('div').withConfig({
    shouldForwardProp: (prop) =>
        ![
            'textColor',
            'buttonColor',
            'size',
            'status',
            'textColor',
            'hasOutline',
            'width',
            'activeButtonColor',
            'leftIcon',
            'rightIcon',
            'hoverButtonColor',
        ].includes(prop),
})`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    border-radius: ${
        ({ size }) =>
            size === 'small'
                ? '10px'
                : size === 'medium'
                ? '14px'
                : '16px' /* for both large, big */
    };


    box-sizing: 'content-box';
    cursor: ${({ status }) =>
        status === 'disabled' ? 'not-allowed' : 'pointer'};
    ${({ status, textColor }) => (status === 'disabled' ? Gray400 : textColor)}
    ${({ status, buttonColor }) =>
        status === 'disabled' ? BgGray200 : buttonColor}
        
    box-shadow: ${({ status, hasOutline = false }) =>
        hasOutline
            ? status === 'disabled'
                ? '0 0 0 1px #D6D6D6 inset'
                : '0 0 0 1px #EBEBEB inset'
            : ''};

    width: ${({ width }) => width};
    height: ${
        ({ size }) =>
            size === 'small'
                ? '36px'
                : size === 'medium'
                ? '48px'
                : size === 'large'
                ? '52px'
                : '56px' /* big */
    };

    ${({ size }) =>
        size === 'small'
            ? Header14
            : size === 'medium'
            ? Header15
            : size === 'large'
            ? Header16
            : Header18};

    @media (min-width: 791px) {
        :hover {
            ${({ status, hoverButtonColor }) =>
                status !== 'disabled' && hoverButtonColor}

            box-shadow: ${({ status, hasOutline = false }) =>
                hasOutline &&
                status !== 'disabled' &&
                '0 0 0 1px #999999 inset'};
        }

        :active {
            ${({ status, activeButtonColor }) =>
                status !== 'disabled' && activeButtonColor}

            box-shadow: ${({ status, hasOutline = false }) =>
                hasOutline &&
                status !== 'disabled' &&
                '0 0 0 1px #C2C2C2 inset'};
        }
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${({ size }) => (size === 'small' ? '14px' : '24px')};
        height: ${({ size }) => (size === 'small' ? '14px' : '24px')};
        margin-right: ${({ leftIcon }) => leftIcon && '4px'};
        margin-left: ${({ rightIcon }) => rightIcon && '4px'};
    }
`;

CustomButton.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large', 'big']).isRequired,
    leftIcon: PropTypes.element,
    rightIcon: PropTypes.element,
    status: PropTypes.string,
    text: PropTypes.string,
    textColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    buttonColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    hoverButtonColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    activeButtonColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    hasOutline: PropTypes.bool,
};

export default CustomButton;
