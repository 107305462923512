import styled from "styled-components";

export const ApplyButtonScreenWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  margin-left: calc(-50vw + 50%);
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9;

  @media (max-width: 791px) {
    padding-bottom: 20px;
  }
`;

export const ApplyButton = styled.img`
  padding-top: 112px;
  padding-bottom: 200px;

  width: 404px;
  height: 120px;

  cursor: pointer;
  @media (max-width: 791px) {
    padding-top: 5px;
    padding-bottom: 70px;
    width: 153px;
    height: 46px;
  }
`;

export const ScrollImgBox = styled.div`
  padding-top: 200px;
  background-color: #f9f9f9;
  .scroll {
    position: relative;
    display: flex;
    width: 100%;
  }

  .scroll div {
    white-space: nowrap;
    animation: scroll var(--time) linear infinite;
  }
  @media (max-width: 791px) {
    padding-top: 60px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-1500%);

      transform: ${({ isMobile }) =>
        isMobile === false ? "translateX(-1500%)" : "translateX(-4500%)"};
    }
  }

  .imgBox div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imgBox img {
    padding: 0 10px;
    width: 503px;
    height: 346px;

    @media (max-width: 791px) {
      padding: 0 7px;
      width: 251.5px;
      height: 173px;
    }
  }
`;

export const logoImg = styled.img`
  margin-top: 130px;
  width: 934px;
  height: 255px;
  @media (max-width: 791px) {
    margin-top: 60px;
    width: 236px;
    height: 64px;
  }
`;
