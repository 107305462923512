import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Header30,
  Header48,
  Title18,
  Title20,
} from "../_foundation/styleGuide/typography";
import { Gray700 } from "../_foundation/styleGuide/colors";
import { useMediaQuery } from "react-responsive";

const InfiniteScrollScreen = () => {
  const [isMobile, setIsMobile] = useState(false);
  const mobile = useMediaQuery({
    query: "(max-width:791px)",
  });

  useEffect(() => {
    if (mobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [mobile]);

  return (
    <StyledInfiniteScrollScreen isMobile={isMobile}>
      <div className="textWrap">
        <div className="title">
          사공사에서 한 번에
          <br />
          가격비교할 수 있는 플랫폼
        </div>
        <div className="subTitle">
          앞으로 더 많은 서비스들을 추가할 예정이에요.
        </div>
      </div>
      <div
        className="scroll imgBox"
        style={{ "--time": isMobile ? "360s" : "1000s" }}
      >
        <div>
          {[...Array(30)].map((_, index) => (
            <React.Fragment key={index}>
              <img src="/assets/platform/11st.png" alt="" />
              <img src="/assets/platform/coupang.png" alt="" />
              <img src="/assets/platform/gmarket.png" alt="" />
              <img src="/assets/platform/lotteon.png" alt="" />
              <img src="/assets/platform/wmp.png" alt="" />
              <img src="/assets/platform/auction.png" alt="" />
              <img src="/assets/platform/ssg.png" alt="" />
              <img src="/assets/platform/naver.png" alt="" />
              <img src="/assets/platform/ohouse.png" alt="" />
              <img src="/assets/platform/danawa.png" alt="" />
              <img src="/assets/platform/musinsa.png" alt="" />
              <img src="/assets/platform/kurly.png" alt="" />
            </React.Fragment>
          ))}
        </div>
      </div>
    </StyledInfiniteScrollScreen>
  );
};

const StyledInfiniteScrollScreen = styled.div`
  overflow: hidden;
  width: 100vw;
  margin-left: calc(-50vw + 50%);

  .textWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    margin-top: 100px;
    margin-bottom: 90px;

    @media (max-width: 791px) {
      margin-top: 0px;
      margin-bottom: 40px;
    }

    .title {
      ${Header48}
      margin-bottom : 20px;

      @media (max-width: 791px) {
        ${Header30}
      }
    }

    .subTitle {
      ${Title20}
      ${Gray700}

            @media (max-width: 791px) {
        ${Title18}
      }
    }
  }

  .scroll {
    position: relative;
    display: flex;
    width: 100%;
  }

  .scroll div {
    white-space: nowrap;
    animation: scroll var(--time) linear infinite;
  }

  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-1500%);

      transform: ${({ isMobile }) =>
        isMobile === false ? "translateX(-1500%)" : "translateX(-4500%)"};
    }
  }

  .imgBox div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imgBox img {
    padding: 0 20px;
    width: 110px;
    height: 110px;

    @media (max-width: 791px) {
      padding: 0 12px;
      width: 70px;
      height: 70px;
    }
  }
`;

export default InfiniteScrollScreen;
